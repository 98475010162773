// eslint-disable-next-line
import React, { Component } from 'react';

class Example extends Component {
    render() {
        return (
            <>{process.env.REACT_APP_TEST_VAR}</>
        );
    }
}

export default Example;
