import React, { Component } from 'react';
import { FirebaseLoginUI } from './FirebaseAuth';

class Login extends Component {
    render() {
        return (
            <FirebaseLoginUI />
        );
    }
}

export default Login;
