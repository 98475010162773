import { Spinner } from "@blueprintjs/core";
import firebase from 'firebase/app';
import 'firebase/auth';
import React, { Component } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

const DEFAULT_FIREBASE_CONTEXT = {
    authStatusReported: false,
    isUserSignedIn: false,
    user: null,
};
const FIREBASE_CONFIG = {
    apiKey: "AIzaSyBCv0pcWviRrJKZ-DtlQSocPeZbskOGJL0",
    authDomain: "bq-puzzles.firebaseapp.com",
    databaseURL: "https://bq-puzzles.firebaseio.com",
    projectId: "bq-puzzles",
    storageBucket: "bq-puzzles.appspot.com",
    messagingSenderId: "965716052597"
};

firebase.initializeApp(FIREBASE_CONFIG);
const firebaseAuth = firebase.auth();

// Module Exports
export const FirebaseAuthContext = React.createContext(DEFAULT_FIREBASE_CONTEXT);

export const FirebaseAuthConsumer = FirebaseAuthContext.Consumer;

export class FirebaseAuthProvider extends Component {
    constructor(props) {
        super(props)
        this.state = DEFAULT_FIREBASE_CONTEXT;
    }

    componentDidMount() {
        firebaseAuth.onAuthStateChanged(user => {
            this.setState({
                authStatusReported: true,
                isUserSignedIn: !!user,
                user: user,
            });
        });
    }

    render() {
        const { children } = this.props;
        const { authStatusReported, isUserSignedIn, user } = this.state;
        return (
            <FirebaseAuthContext.Provider value={{ isUserSignedIn, authStatusReported, user }}>
                {authStatusReported ? children : <Spinner className="center-spinner" />}
            </FirebaseAuthContext.Provider>
        );
    }
}

export class FirebaseLoginUI extends Component {
    render() {
        let uiConfig = {
            signInFlow: 'popup',
            signInSuccessUrl: '/',
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID
            ]
        };
        return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />;
    }
}

export const signOut = () => {
    firebaseAuth.signOut();
}
