import React, { Component } from 'react';
import './Puzzle.css';
import Body from './PuzzleBody';
import Form from './PuzzleForm';
import Sidebar from './PuzzleSidebar';


class Puzzle extends Component {
    render() {
        const puzzleId = this.props.match.params.id
        return (
            <div className="cont">
                <main>
                    <Body puzzleId={puzzleId} />
                    <Form puzzleId={puzzleId} />
                </main>
                <aside>
                    <Sidebar puzzleId={puzzleId} />
                </aside>
            </div>
        );
    }
}

export default Puzzle;
