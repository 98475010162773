import path from 'path';

const CONTENT_DIR = 'content';

class Resource {
    constructor(id) {
        this.id = id;
    }

    static fromContentPath(contentPath) {
        const id = path.parse(path.relative(this.dirName, contentPath)).name;
        return new this(id);
    }

    static get dirName() {
        return null;
    }

    get contentPath() {
        return path.join(this.constructor.dirName, `${this.id}.md`);
    }

    get resourceUrl() {
        return '/' + path.join(CONTENT_DIR, this.contentPath);
    }
}

export class PuzzleResource extends Resource {
    static get dirName() {
        return 'puzzles';
    }
}
