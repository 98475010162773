import Api from 'api/api';
import React, { Component } from 'react';
import { FirebaseAuthContext } from "./FirebaseAuth";
import PuzzlesTable from "./PuzzlesTable";

class PuzzlesList extends Component {
    constructor(props) {
        super(props);
        this.api = new Api();
        this.state = {
            data: null,
            isLoading: false,
            error: null,
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.api.getPuzzlesList()
            .then(data => this.setState({ data: data, isLoading: false }))
    }

    render() {
        const { data, isLoading } = this.state;
        if (isLoading) return <p>Loading...</p>;
        if (data == null) return <p>Puzzles not found</p>;

        return (
            <PuzzlesTable data={data} />
        );
    }
}
PuzzlesList.contextType = FirebaseAuthContext;

export default PuzzlesList;
