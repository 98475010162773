import { Alignment, Button, Menu, Navbar, Popover } from "@blueprintjs/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import UserAvatar from "react-user-avatar";
import { FirebaseAuthContext, signOut } from "./FirebaseAuth";
import "./Nav.css";

class NavButton extends Component {
    render() {
        return (
            <Link to="/puzzles" style={{ textDecoration: 'none' }}>
                <Button className="bp3-minimal" icon={ this.props.icon } text={ this.props.text } />
            </Link>
        );
    }
}

class NavLoggedIn extends Component {
    render() {
        if (!this.context.isUserSignedIn) {
            return null;
        }
        let user = this.context.user;
        return (
            <>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Divider />
                    <NavButton icon="left-join" text="Puzzles" />
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <Popover content={<UserMenu />} target={
                        <Button className="bp3-minimal" icon="user" text={user.displayName} />
                    } />
                </Navbar.Group>
            </>
        );
    }
}
NavLoggedIn.contextType = FirebaseAuthContext;

class UserMenu extends Component {
    render() {
        let user = this.context.user;
        return (
            <>
                <div id="nav-menu-profile">
                    <UserAvatar size="48" name={user.displayName} src={user.photoURL} color="#BFCCD6" />
                    <div id="nav-menu-name" className="">{user.displayName}</div>
                    <div className="bp3-text-muted bp3-text-small">{user.email}</div>
                </div>
                <Menu>
                    <Menu.Divider />
                    <Menu.Item icon="log-out" onClick={signOut} text="Sign out" />
                </Menu>
            </>
        );
    }
}
UserMenu.contextType = FirebaseAuthContext;

class Nav extends Component {
    render() {
        return (
            <Navbar>
                <div style={{ margin: "0 auto", width: "1200px" }}>
                    <Navbar.Group align={Alignment.LEFT}>
                        <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                            <Navbar.Heading>BQ Puzzles</Navbar.Heading>
                        </Link>
                    </Navbar.Group>
                    <NavLoggedIn />
                </div>
            </Navbar>
        );
    }
}

export default Nav;
