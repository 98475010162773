import Api from 'api/api';
import React, { Component } from 'react';

class PuzzleSidebar extends Component {
    constructor(props) {
        super(props);
        this.api = new Api();
        this.state = {
            puzzle: null,
            isLoading: false,
            error: null,
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.api.getPuzzle(this.props.puzzleId)
            .then(data => this.setState({ puzzle: data, isLoading: false }))
    }

    render() {
        const { puzzle, isLoading } = this.state;

        if (isLoading) {
            return <p>Loading...</p>
        }

        if (puzzle == null) {
            return <p>Puzzle not found</p>
        }

        return (
            <div>Sidebar: {this.props.puzzleId}</div>
        );
    }
}

export default PuzzleSidebar;
