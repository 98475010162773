import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Row extends Component {
    render() {
        const { puzzle, history } = this.props;
        const { id, metadata } = puzzle;
        const { title, tags } = metadata;
        return (
            <tr onClick={() => history.push(`/puzzle/${id}`)}>
                <td>{ id }</td>
                <td>{ title }</td>
                <td>{ tags }</td>
            </tr>
        );
    }
}
Row = withRouter(Row);

export default class PuzzlesTable extends Component {
    render() {
        const { data, isLoading } = this.props;
        if (isLoading) return <p>Loading...</p>;
        if (data == null) return <p>Puzzles not found!</p>;

        return (
            <table className="bp3-html-table bp3-interactive">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Tags</th>
                    </tr>
                </thead>
                <tbody>
                    {data.puzzles.map((p) => <Row puzzle={p} key={p.id}/>)}
                </tbody>
            </table>
        );
    }
}
