import Api from 'api/api';
import React, { Component } from 'react';
import { FirebaseAuthContext } from "./FirebaseAuth";
import { Spinner } from "@blueprintjs/core";

export default class Solution extends Component {
    constructor(props) {
        super(props);
        this.api = new Api();
        this.state = {
            solution: null,
            isLoading: false,
            error: null,
        };
    }

    componentDidMount() {
        const solutionId = this.props.match.params.id
        const user = this.context.user

        this.setState({ isLoading: true });
        this.api.getSolution(user, solutionId)
            .then(response => this.setState({ solution: response }))
            .catch(error => this.setState({ error: error }))
            .finally(() => this.setState({ isLoading: false }));
    }

    render() {
        const { solution, isLoading, error } = this.state;

        if (isLoading) {
            return <Spinner />
        } else if (error != null) {
            return <p>Error {!!error.response ? error.response : ""}</p>
        } else if (solution == null) {
            return <p>Solution not found</p>
        }
        console.log(solution.result.done)
        return (
            <>
            <div>Done: {solution.result.done.toString()}</div>
            <div>Success: {solution.result.success.toString()}</div>
            <div>Query: {solution.query}</div>
            </>
        );
    }
}
Solution.contextType = FirebaseAuthContext;
