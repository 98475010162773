import matter from 'gray-matter'
import axios from 'axios'
import { PuzzleResource } from 'utils/resources'

const fileHost = axios;
const server = axios.create({
    baseURL: 'https://us-central1-bq-puzzles.cloudfunctions.net/',
});

class Api {
    async getPuzzle(id) {
        const url = new PuzzleResource(id).resourceUrl;
        const response = await fileHost.get(url);
        const puzzle = matter(response.data);
        if (!('title' in puzzle.data)) return null;
        return puzzle;
    }

    async getPuzzlesList() {
        const response = await fileHost.get('/generated/index.json');
        return await response.data;
    }

    async getAuthHeaders(user) {
        const token = await user.getIdToken();
        return {
            'Authorization': 'Bearer ' + token
        }
    }

    async submitSolution(user, puzzleId, query) {
        const headers = await this.getAuthHeaders(user);
        const response = await server.post('submit_solution', { puzzleId, query }, { headers });
        return response.data;
    }

    async getSolution(user, solutionId) {
        const headers = await this.getAuthHeaders(user);
        const response = await server.post('get_solution', { solution_id: solutionId }, { headers });
        return response.data;
    }
}

export default Api;