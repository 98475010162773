import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import './App.css';
import Example from "./Example";
import { FirebaseAuthConsumer, FirebaseAuthProvider } from "./FirebaseAuth";
import Login from "./Login";
import Nav from "./Nav";
import NoMatch from "./NoMatch";
import Puzzle from "./Puzzle";
import Solution from "./Solution"
import PuzzlesList from "./PuzzlesList";

class ProtectedRoute extends Component {
  render() {
    const { component: Component, ...props } = this.props
    return (
      <FirebaseAuthConsumer>
        {
          ({ isUserSignedIn }) => {
            return <Route
              {...props}
              render={props => (isUserSignedIn ? <Component {...props} /> : <Redirect to='/login' />)}
            />
          }
        }
      </FirebaseAuthConsumer>
    )
  }
}

class App extends Component {
  render() {
    return (
      <FirebaseAuthProvider>
        <Router>
          <div className="App">
            <Nav />
            <Switch>
              <Route exact path="/login" component={Login} />
              <ProtectedRoute exact path="/" component={Example} />
              <ProtectedRoute exact path="/example" component={Example} />
              <ProtectedRoute exact path="/puzzles" component={PuzzlesList} />
              <ProtectedRoute path="/puzzle/:id" component={Puzzle} />
              <ProtectedRoute path="/solution/:id" component={Solution} />
              <Route component={NoMatch} />
            </Switch>
          </div>
        </Router>
      </FirebaseAuthProvider>
    );
  }
}

export default App;
