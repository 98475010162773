import { Button, Divider, Intent } from "@blueprintjs/core";
import Api from 'api/api';
import React, { Component } from 'react';
import AceEditor from 'react-ace';
import { withRouter } from 'react-router-dom';
import { FirebaseAuthContext } from "./FirebaseAuth";
import Toaster from "./Toaster";

import 'brace/mode/mysql';
import 'brace/theme/solarized_dark';

const DEFAULT_QUERY = `#standardSQL
SELECT
    1 + 2 * hello as hi,
    "sadasd" as da
FROM
    hello.asd
`

class PuzzleForm extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false, query: DEFAULT_QUERY };
        this.aceEditor = React.createRef();
    }

    handleSubmit() {
        const { history, puzzleId } = this.props;
        const api = new Api();
        const solution = this.aceEditor.current.editor.getValue();
        this.setState({ isLoading: true });
        api.submitSolution(this.context.user, puzzleId, solution)
            .then((response) => history.push(`/solution/${response.solution_id}`))
            .catch((error) => {
                Toaster.show({
                    message: `Failed to submit solution: ${error.message}`,
                    icon: "warning-sign",
                    intent: Intent.DANGER
                });
                this.setState({ isLoading: false });
            });
    }

    handleEditorChange(value) {
        this.setState({query: value});
    }

    render() {
        const { isLoading, query } = this.state;
        if (!!this.aceEditor.current) {
            this.aceEditor.current.editor.setReadOnly(isLoading);
        }
        return (
            <>
                <Divider />
                <h3 className="bp3-heading">Submit solution</h3>
                <AceEditor
                    ref={this.aceEditor}
                    mode="mysql"
                    theme="solarized_dark"
                    height="300px"
                    width={null}
                    value={query}
                    editorProps={{ $blockScrolling: true }}
                    onChange={this.handleEditorChange.bind(this)}
                />
                <Button text="Submit" loading={isLoading} onClick={this.handleSubmit.bind(this)} />
            </>
        );
    }
}

export default withRouter(PuzzleForm);

PuzzleForm.contextType = FirebaseAuthContext;