import Api from 'api/api';
import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';

class PuzzleBody extends Component {
    constructor(props) {
        super(props);
        this.api = new Api();
        this.state = {
            puzzle: null,
            isLoading: false,
            error: null,
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.api.getPuzzle(this.props.puzzleId)
            .then(data => this.setState({ puzzle: data, isLoading: false }))
    }

    render() {
        const { puzzle, isLoading } = this.state;

        if (isLoading) {
            return <p>Loading...</p>
        }

        if (puzzle == null) {
            return <p>Puzzle not found</p>
        }

        return (
            <ReactMarkdown source={puzzle.content} escapeHtml={false}/>
        );
    }
}

export default PuzzleBody;
